import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { LayoutParams } from '../../config/LayoutParams';
import LoginNovoView from '../User/LoginNovoView';
import LogoffView from '../User/LogoffView';
import NotFoundView from '../NotFoundView';
import ErrorView from '../ErrorView';
import MainLayout from '../../components/MainLayout';
import DemonstracaoDeComponentesView from '../DemonstracaoDeComponentesView';
import HomeView from '../HomeView';
import PerfilDeUsuarioView from './PerfilDeUsuarioView';
import RotinaDoSistemaView from '../RotinaDoSistemaView';
import ParametroDoSistemaView from './ParametroDoSistemaView';
import CargoTecnometricsView from './CargoTecnometricsView';
import EmpresaView from './EmpresaView';
import CidadeView from '../CidadeView';
import FuncionarioTecnometricsView from './FuncionarioTecnometricsView';
import TermoDeUsoView from './TermoDeUsoView';
import AboutView from '../AboutView';
import LogView from '../LogView';
import PackageView from '../PackageView';
import TermosDeUsoView from '../TermosDeUso';

export default class LayoutTecnometrics extends React.Component {
   render() {
      let sessionManager = this.props.sessionManager;
      var lang = this.props.lang;
      this.api = this.props.api;
      return (
         <HashRouter>
            <MainLayout
               menuItems={this.props.menus.getMenusTecnometrics(sessionManager, lang)}
               imgHomeLogo={LayoutParams.imgHomeLogo}
               login={this.props.login}
               lang={lang}
               alterarIdioma={this.props.alterarIdioma}
               mostrarDadosDaConta={false}
               api={this.api}
            >
               <Switch>
                  <Route
                     exact
                     path='/'
                     render={() => <HomeView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/login'
                     render={() => <LoginNovoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route path='/logoff' render={() => <LogoffView api={this.api} lang={lang} />} />
                  <Route
                     path='/error'
                     render={() => <ErrorView api={this.api} lang={lang} error={this.state.error} />}
                  />
                  <Route path='/components' render={() => <DemonstracaoDeComponentesView api={this.api} />} />
                  <Route path='/about' render={() => <AboutView api={this.api} lang={lang} />} />

                  <Route
                     path='/1011'
                     render={() => <PerfilDeUsuarioView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1021'
                     render={() => (
                        <FuncionarioTecnometricsView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1031'
                     render={() => <RotinaDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/1041'
                     render={() => (
                        <ParametroDoSistemaView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1061'
                     render={() => (
                        <CargoTecnometricsView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1101'
                     render={() => (
                        <EmpresaView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />

                  <Route
                     path='/1111'
                     render={() => <CidadeView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />

                  <Route
                     path='/9999'
                     render={() => (
                        <DemonstracaoDeComponentesView api={this.api} lang={lang} sessionManager={sessionManager} />
                     )}
                  />

                  <Route
                     path='/1041'
                     render={() => (
                        <CargoTecnometricsView
                           api={this.api}
                           lang={lang}
                           sessionManager={sessionManager}
                           consultaDeCep={this.props.consultaDeCep}
                        />
                     )}
                  />
                  <Route
                     path='/1381'
                     render={() => <TermoDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/90003'
                     render={() => <TermosDeUsoView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route
                     path='/9001'
                     render={() => <LogView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                                    <Route
                     path='/pacotes'
                     render={() => <PackageView api={this.api} lang={lang} sessionManager={sessionManager} />}
                  />
                  <Route render={() => <NotFoundView lang={lang} />} />
               </Switch>
            </MainLayout>
         </HashRouter>
      );
   }
}
